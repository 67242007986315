<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        Parmi les interactions intermoléculaires suivantes, indiquez laquelle a la
        <b>{{ effectDirection }}</b> influence sur la polarité d’un composé en chromatographie?
      </p>

      <v-radio-group
        v-model="inputs.IMFEffectsOnPolarity"
        :disabled="!allowEditing"
        class="pl-6 mb-n6"
      >
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'uOttawa1321Prelab1Q4Fr',
  components: {
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        IMFEffectsOnPolarity: null,
      },
      options: [
        {text: 'Intéractions dipôle-dipôle', value: 'DD'},
        {text: 'Liaison hydrogène', value: 'HB'},
        {text: 'Intéractions dispersion de London', value: 'LDF'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    effectDirection() {
      if (this.versionNumber.value === 1) {
        return 'petite';
      } else {
        return 'plus';
      }
    },
  },
};
</script>
